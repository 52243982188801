<template>
  <a-modal
    title="备份与恢复"
    width="60%"
    :footer="null"
    :visible="true"
    :maskClosable="false"
    @cancel="$emit('close')"
  >
    <div class="backup-resotre-container">
      <a-button
        type="primary"
        :class="['backup-btn', !isAllowBackup && 'disabled']"
        @click="backupNow"
        >立即备份</a-button
      >

      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        bordered
        :loading="loading"
        :data-source="tableData"
        :pagination="paginationOpt"
      >
        <template slot="action" slot-scope="row">
          <i
            title="恢复备份"
            class="restore-btn btn"
            @click="restoreBackup(row.backupBatchId, row.backupTime)"
          />
        </template>
      </a-table>
    </div>

    <!-- 立即备份进度提示弹窗 -->
    <a-modal
      title="备份"
      :visible="isBackupProgressModalShow"
      :footer="null"
      :keyboard="false"
      :maskClosable="false"
      :closable="false"
      centered
    >
      <p>请等待······</p>
      <p>行为审计备份中，请勿进行其他操作！</p>
      <a-progress :percent="progressPercent" :showInfo="false" />
      <a-button
        style="display: block;margin: 10px auto 0;"
        @click="cancelBackup"
        >取消备份</a-button
      >
    </a-modal>

    <!-- 恢复备份遮罩弹窗 -->
    <a-modal
      :visible="isWarningShow"
      :footer="null"
      :keyboard="false"
      :maskClosable="false"
      :closable="false"
      centered
    >
      <div style="align-items: center;display: flex;padding-bottom: 20px;">
        <a-spin style="margin-right: 10px;" />
        <span style="font-size: 18px;">恢复备份中，请勿进行其它操作！</span>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
export default {
  name: 'fileImport',
  props: {},
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: '备份时间',
          key: 'backupTime',
          dataIndex: 'backupTime',
          ellipsis: true,
          width: 160,
        },
        {
          title: '备份数',
          key: 'backupNum',
          dataIndex: 'backupNum',
        },
        {
          title: '备份账号',
          key: 'loginName',
          dataIndex: 'loginName',
        },
        {
          title: '真实姓名',
          key: 'userName',
          dataIndex: 'userName',
        },
        {
          title: '联系方式',
          key: 'phoneNumber',
          dataIndex: 'phoneNumber',
        },
        {
          title: '管辖单位',
          key: 'organizationName',
          dataIndex: 'organizationName',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 50,
        },
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        onShowSizeChange: (current, pageSize) =>
          this.updatePagination(current, pageSize),
        // 改变每页数量时更新显示
        onChange: (current, pageSize) =>
          this.updatePagination(current, pageSize),
      },
      loading: true,
      isBackupProgressModalShow: false, // 立即备份进度提示弹窗显隐
      isAllowBackup: true, // 允许备份按钮事件触发
      isCancelBackup: false, // 取消备份触发
      backupTimeStamp: 0, // 备份触发的时间戳
      progressPercent: 0, // 备份进度条值
      isWarningShow: false, // 恢复备份遮罩层显隐
    };
  },

  methods: {
    // 立即备份
    backupNow() {
      this.progressPercent = 0;
      this.isBackupProgressModalShow = true;
      this.isAllowBackup = false;
      setTimeout(() => {
        this.isAllowBackup = true;
      }, 5000);
      this.backupTimeStamp = Date.now();
      this.$api.aiCenterS
        .backupAction({
          backTime: this.backupTimeStamp,
        })
        .then(res => {
          this.progressPercent = 50;
          if (res.status === 200 && res.data.code === 200) {
            this.progressPercent = 100;
            setTimeout(() => {
              if (
                res.data.data == this.backupTimeStamp &&
                !this.isCancelBackup
              ) {
                this.isBackupProgressModalShow = false;
                this.$message.success('备份成功');
                this.getTableData();
                this.$emit('updateTableData');
              }
            }, 100);
          }
        });
    },

    // 取消备份
    cancelBackup() {
      const _this = this;
      this.isCancelBackup = true;
      this.isBackupProgressModalShow = false;
      this.$api.aiCenterS.cancelBackup().then(res => {
        if (res.status === 200 && res.data.code === 200) {
          _this.$message.info('已取消备份');
        }
      });
    },

    // 恢复备份
    restoreBackup(backupBatchId, backupTime) {
      console.log(backupBatchId);
      const _this = this;
      this.$confirm({
        title: `确定恢复 "${backupTime}" 的行为审计记录？`,
        content:
          '您选择的行为审计将替换您当前的行为审计记录。为保证您的正常使用，请先备份当前的行为审计记录后执行此操作。',
        centered: true,
        onOk() {
          _this.isWarningShow = true;
          _this.$api.aiCenterS
            .restoreAction(backupBatchId)
            .then(res => {
              if (res.status === 200 && res.data.code === 200) {
                _this.$message.success('恢复备份成功');
                _this.$emit('updateTableData');
              }
            })
            .finally(() => {
              _this.isWarningShow = false;
            });
        },
        onCancel() {},
      });
    },

    // pagination事件统一处理
    updatePagination(current, pageSize) {
      this.paginationOpt.current = current;
      this.paginationOpt.defaultPageSize = pageSize;
      this.getTableData();
    },

    // 获取表格数据
    getTableData() {
      this.loading = true;
      this.$api.aiCenterS
        .getActionBackupList({
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        })
        .then(res => {
          if (res.status === 200 && res.data.code === 200) {
            this.tableData = res.data.data;
            this.paginationOpt.total = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getTableData();
  },
};
</script>

<style scoped lang="less">
.backup-resotre-container {
  .backup-btn {
    margin-bottom: 1vw;
    &.disabled {
      pointer-events: none;
    }
  }

  ::v-deep .ant-table-wrapper {
    .ant-table-body {
      .ant-table-thead {
        tr {
          th {
            padding: 16px 2px;
            text-align: center;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 16px 2px;
            text-align: center;

            .restore-btn {
              background: url(~@assets/home/restore_backup.png) 0 0 / contain
                no-repeat;
              cursor: pointer;
              display: block;
              height: 18px;
              margin: 0 auto;
              width: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
