<template>
  <div class="full-width full-height audit-action-containter">
    <back url="/home" v-show="false" />
    <tab :tabs="tabs" class="m-b" :initTab="initTab" @select="selectHandle" />
    <!-- <div class="fl-menu flex m-t m-b items-center">
      <span
        :class="[{ current: activeMenu === 0 }, 'pointer']"
        @click="tabClick(0)"
        >操作日志</span
      >
      <span
        :class="[{ current: activeMenu === 1 }, 'pointer']"
        @click="tabClick(1)"
        >行为审计</span
      >
    </div> -->
    <!-- 操作日志 -->
    <div>
      <!-- <div
        class="flex items-center justify-between m-b-sm"
        v-show="activeMenu === 0"
      >
        <div>
          <a-input
            class="m-r-sm"
            placeholder="请输入用户名"
            style="width: 180px"
            v-model="searchParams.operateUserIds"
          />
          <a-input
            placeholder="请输入姓名"
            class="m-r-sm"
            style="width: 180px"
            v-model="searchParams.operateUserNames"
          />
         
          <a-range-picker
            class="m-r-sm"
            style="width: 330px"
            v-model="searchParams.dataPicker"
            @change="onChangeDate"
          >
          </a-range-picker>
          <a-button
            type="primary"
            class="m-r-sm btn-search"
            @click="searchHandle"
          >
            搜索
          </a-button>
          <a-button class="btn-reset" @click="resetHandle"> 重置 </a-button>
        </div>
        <div class="flex">
          <span class="span-line m-l-md m-r-md"></span>
          <a-button class="btn-export" @click="exportHandle">
            数据导出
          </a-button>
        </div>
      </div> -->
      <!-- 操作条 -->
      <div class="table-search-actions-bar" v-show="activeMenu === 1">
        <div class="search-bar-wrapper">
          <div class="conditions">
            <!-- 审计类型 -->
            <div class="label">
              <span>审计类型：</span>
              <a-select
                placeholder="请选择审计类型"
                class="m-r-sm"
                v-model="behaviorParam.actionName"
                allowClear
              >
                <a-select-option
                  v-for="item in shotList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>

            <!-- 时间 -->
            <div class="label">
              <span>起止时间：</span>
              <a-range-picker
                v-model="behaviorParam.timeRange"
                :show-time="{ format: 'HH:mm:ss' }"
                class="m-r-sm"
              />
            </div>

            <!-- 用户名 -->
            <div class="label">
              <span>用户名：</span>
              <a-input
                placeholder="请输入用户名"
                class="m-r-sm"
                v-model="behaviorParam.operateLoginName"
                allowClear
              />
            </div>

            <!-- 真实姓名 -->
            <div class="label">
              <span>真实姓名：</span>
              <a-input
                placeholder="请输入真实姓名"
                class="m-r-sm"
                v-model="behaviorParam.operateUserName"
                allowClear
              />
            </div>

            <!-- 手机号 -->
            <div class="label">
              <span>手机号：</span>
              <a-input
                placeholder="请输入手机号"
                class="m-r-sm"
                v-model="behaviorParam.operatePhoneNum"
                allowClear
              />
            </div>

            <!-- IP地址 -->
            <div class="label">
              <span>IP地址：</span>
              <a-input
                placeholder="请输入IP地址"
                class="m-r-sm"
                v-model="behaviorParam.ip"
                allowClear
              />
            </div>

            <!-- 所属单位 -->
            <div class="label">
              <span>所属单位：</span>
              <a-cascader
                :options="units"
                expand-trigger="click"
                placeholder="请选择所属单位"
                v-model="behaviorParam.organizationArrId"
                change-on-select
                :display-render="({ labels }) => labels.slice(-1)"
                @change="unitChange"
              />
            </div>

            <div class="search-btns-wrapper">
              <a-button
                type="primary"
                class="m-r-sm btn-search"
                @click="searchAction"
              >
                查询
              </a-button>
              <a-button class="btn-reset" @click="rest"> 重置 </a-button>
            </div>
          </div>
        </div>
        <div class="actions-bar-wrapper">
          <span class="span-line m-l-md m-r-md"></span>
          <a-button class="btn-export" @click="isFileImportShow = true"
            >数据导入</a-button
          >
          <a-button class="btn-export m-l-sm m-r-sm" @click="exportHandle">
            数据导出
          </a-button>
          <a-button
            class="btn-export"
            @click="isBackupRestoreShow = true"
            >备份与恢复</a-button
          >
        </div>
      </div>
    </div>
    <!-- 行为审计 -->

    <!-- 行为审计表格 -->
    <a-table
      :loading="loading"
      :columns="columns"
      :components="resibleTableHeader"
      :rowKey="(record, index) => index"
      bordered
      :data-source="behaviorList"
      :pagination="paginationOpt2"
      :scroll="scroll"
    >
      <template slot="action" slot-scope="rowText, records">
        <span v-if="records.actionName !== '登录系统'">
          <i
            title="撤销"
            v-if="rowText.revokeStatus == 0"
            :class="[
              'backward-btn btn',
              rowText.revokeStatus == 1 && 'disabled',
            ]"
            @click="backwardAction(rowText.description, rowText.id)"
          />
        </span>
      </template>
    </a-table>

    <!-- 数据导入弹窗 -->
    <file-import
      uploadUrl="/data/action-log/action-import"
      v-if="isFileImportShow"
      @close="
        isFileImportShow = false;
        _getAnalyze();
      "
    />

    <!-- 备份与恢复弹窗 -->
    <backup-restore
      v-if="isBackupRestoreShow"
      @close="isBackupRestoreShow = false"
      @updateTableData="_getAnalyze"
    />
  </div>
</template>

<script>
import Back from '../../components/Back.vue';
import { handleExportFile } from '@/tools';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import fileImport from '@components/FileImport';
import backupRestore from './BackupRestore';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'LogAction',
  mixins: [tabMixin,ResizableTable],
  components: {
    Back,
    Tab,
    fileImport,
    backupRestore,
  },
  data() {
  /*   const columns = [
      {
        title: '序号',
        customRender: (text, record, index) =>
          `${(this.paginationOpt1.current - 1) *
            this.paginationOpt1.defaultPageSize +
            index +
            1}`,
        width: 80,
      },
      {
        title: '操作功能',
        dataIndex: 'feature',
        key: 'feature',
        width: 160,
      },
      {
        title: '操作详情',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
        width: 200,
      },
      {
        title: '操作时间',
        dataIndex: 'operateTime',
        key: 'operateTime',
        ellipsis: true,
        width: 200,
      },
      {
        title: '用户名',
        dataIndex: 'gmtCreateName',
        key: 'gmtCreateName',
        ellipsis: true,
        width: 140,
      },
      {
        title: '姓名',
        dataIndex: 'operateUserName',
        key: 'operateUserName',
        ellipsis: true,
        width: 140,
      },
      {
        title: '电话',
        dataIndex: 'operateUserPhone',
        key: 'operateUserPhone',
        ellipsis: true,
        width: 150,
      },
      {
        title: '所属单位',
        dataIndex: 'organizationName',
        key: 'organizationName',
        ellipsis: true,
        width: 150,
      },
    ];
    const columns1 = [
      {
        title: '序号',
        dataIndex:'actionIndex',
        customRender: (text, record, index) => index + 1,
        width: 60,
      },
      {
        title: '审计类型',
        dataIndex: 'actionName',
        key: 'actionName',
        width: 80,
      },
      {
        title: '行为描述',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
        width: 150,
      },
      {
        title: '开始时间',
        dataIndex: 'operateTime',
        key: 'startTime',
        ellipsis: true,
        width: 150,
      },
      {
        title: '执行时间(ms)',
        dataIndex: 'executeTime', // 单位ms
        key: 'actTime',
        ellipsis: true,
        width: 90,
      },
      {
        title: '操作用户名',
        dataIndex: 'operateLoginName',
        key: 'gmtCreateName',
        ellipsis: true,
        width: 100,
      },
      {
        title: '真实姓名',
        dataIndex: 'operateUserName',
        key: 'operateUserName',
        ellipsis: true,
        width: 100,
      },
      {
        title: '联系方式',
        dataIndex: 'operateUserPhone',
        key: 'phone',
        ellipsis: true,
        width: 100,
      },
      {
        title: '所属单位',
        dataIndex: 'organizationName',
        key: 'organizationName',
        ellipsis: true,
        width:120,
      },
      {
        title: 'IP地址',
        dataIndex: 'ip',
        key: 'ip',
        width: 140,
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        width: 50,
      },
    ]; */
    return {
      shotList: [
        {
          id: 0,
          value: '登录系统',
          name: '登录系统',
        },
        {
          id: 1,
          value: '测试路公司',
          name: '测试路公司',
        },
        {
          id: 2,
          value: '添加用户',
          name: '添加用户',
        },
        {
          id: 3,
          value: '修改用户',
          name: '修改用户',
        },
        {
          id: 4,
          value: '用户注册',
          name: '用户注册',
        },
      ],

      // 操作日志
      searchParams: {
        operateUserIds: undefined,
        operateUserNames: undefined,
        dataPicker: null,
      },
      // 行为审计
      behaviorParam: {
        actionName: undefined, // 审计类型
        timeRange: [], // 起始时间
        operateLoginName: '', // 用户名
        operateUserName: '', // 真实姓名
        operatePhoneNum: '', // 手机号
        ip: '',
        organizationId: undefined, // 所属单位
        organizationArrId: [],
      },
      scroll: { y: innerHeight - 390,x:'100%' },
      // 操作日志分页
      paginationOpt1: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt1.current = 1;
          this.paginationOpt1.defaultPageSize = pageSize;
          this._getAnalyze();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt1.current = current;
          this.paginationOpt1.defaultPageSize = size;
          this._getAnalyze();
        },
      },
      // 上传
      headers: {
        authorization: 'authorization-text',
      },
      imports: false,
      recovers: false,
      copys: false,
      loading: true,
      analyzeList: [],
      behaviorList: [],
      // columns,
      activeMenu: 1,
      columns:[
        {
          title: '序号',
          dataIndex:'actionIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '审计类型',
          dataIndex: 'actionName',
          key: 'actionName',
          width: 80,
        },
        {
          title: '行为描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: 180,
        },
        {
          title: '开始时间',
          dataIndex: 'operateTime',
          key: 'operateTime',
          ellipsis: true,
          width: 120,
        },
        {
          title: '执行时间(ms)',
          dataIndex: 'executeTime', // 单位ms
          key: 'executeTime',
          ellipsis: true,
          width: 90,
        },
        {
          title: '操作用户名',
          dataIndex: 'operateLoginName',
          key: 'operateLoginName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '真实姓名',
          dataIndex: 'operateUserName',
          key: 'operateUserName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '联系方式',
          dataIndex: 'operateUserPhone',
          key: 'operateUserPhone',
          ellipsis: true,
          width: 100,
        },
        {
          title: '所属单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          ellipsis: true,
          width:120,
        },
        {
          title: 'IP地址',
          dataIndex: 'ip',
          key: 'ip',
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 50,
        },
      ],
      paginationOpt2: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10, // 总数，必须先有
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt2.current = 1;
          this.paginationOpt2.defaultPageSize = pageSize;
          this._getAnalyze();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt2.current = current;
          this.paginationOpt2.defaultPageSize = size;
          this._getAnalyze();
        },
      },
      isFileImportShow: false, // 数据导入弹窗
      isBackupRestoreShow: false, // 数据导入弹窗
    };
  },
  computed: {
    ...mapState({
      selectList: (state) => state.collect.selectList,
      units: (state) => state.collect.organizationList, // 所属单位可选列表
    }),
  },
  watch: {},
  mounted() {
    this.getOrganationList();
    this._getAnalyze();
    this.getSelectList({ regionCode: window.g.regionCode });
  },
  methods: {
    ...mapActions(['getSelectList', 'getOrganationList']),
    selectHandle(index) {
      this.selectCurrent(index);
    },
    changeProvince(val) {
      this.regionId = val;
    },
    _getAnalyze() {
      this.loading = true;
      const params = {
        ...this.behaviorParam,
        startTime:
          (this.behaviorParam.timeRange[0] &&
            moment(this.behaviorParam.timeRange[0]).format(
              'YYYY-MM-DD HH:mm:ss'
            )) ||
          '',
        endTime:
          (this.behaviorParam.timeRange[1] &&
            moment(this.behaviorParam.timeRange[1]).format(
              'YYYY-MM-DD HH:mm:ss'
            )) ||
          '',
        currPage: this.paginationOpt2.current,
        pageSize: this.paginationOpt2.defaultPageSize,
      };
      delete params.timeRange;

      // 行为审计
      this.$api.aiCenterS.getBehavior(params).then((res) => {
        console.log('res', res);
        if (res.code === 200) {
          this.behaviorList = res.data;
          this.paginationOpt2.total = res.total;
          this.loading = false;
        }
      });
    },
    exportHandle() {
      const _this = this;
      const params = {
        ...this.behaviorParam,
        startTime:
          (this.behaviorParam.timeRange[0] &&
            moment(this.behaviorParam.timeRange[0]).format(
              'YYYY-MM-DD HH:mm:ss'
            )) ||
          '',
        endTime:
          (this.behaviorParam.timeRange[1] &&
            moment(this.behaviorParam.timeRange[1]).format(
              'YYYY-MM-DD HH:mm:ss'
            )) ||
          '',
        currPage: this.paginationOpt2.current,
        pageSize: this.paginationOpt2.defaultPageSize,
      };
      this.$confirm({
        title: '提示',
        content: '确定导出数据文件么?',
        onOk() {
          _this.$fullLoading({
            tip: '导出中···请稍候',
            duration: 0,
          });
          _this.$api.aiCenterS
            .exportActionInfo(params)
            .then((res) => {
              handleExportFile(res.data, '行为审计日志.xls');
            })
            .finally(() => {
              _this.$closeFullLoading();
            });
        },
      });
    },
    //点击切换操作日志 和行为审计
    tabClick(index) {
      this.activeMenu = index;
      console.log(this.activeMenu);
    },
    searchHandle() {
      this._getAnalyze();
    },
    searchAction() {
      this._getAnalyze();
    },
    // 操作时间函数
    onChangeDate(dataPicker) {
      this.searchParams.dataPicker = dataPicker.length > 0 ? dataPicker : null;
    },
    onChangeDateS(dataSelect) {
      this.behaviorParam.dataSelect = dataSelect.length > 0 ? dataSelect : null;
    },

    // 选择所属单位
    unitChange(arryValue) {
      this.behaviorParam.organizationArrId = arryValue;
      this.behaviorParam.organizationId = arryValue.slice(-1)[0];
    },

    // 日志重置按钮
    resetHandle() {
      this.paginationOpt1.current = 1;
      this.searchParams.operateUserIds = '';
      this.searchParams.operateUserNames = '';
      this.searchParams.dataPicker = null;
      this._getAnalyze();
    },
    // 行为审计重置
    rest() {
      this.behaviorParam.regionId = undefined;
      this.paginationOpt2.current = 1;
      this.behaviorParam.type = undefined;
      this.behaviorParam.dataSelect = '';
      this.behaviorParam.name = '';
      this.behaviorParam = {
        actionName: undefined, // 审计类型
        timeRange: [], // 起始时间
        operateLoginName: '', // 用户名
        operateUserName: '', // 真实姓名
        operatePhoneNum: '', // 手机号
        ip: '',
        organizationId: undefined, // 所属单位
        organizationArrId: [],
      };
      this._getAnalyze();
    },
    // 备份
    copy() {
      this.$message.success('备份成功!!', 0.5);
    },

    // 恢复
    recover() {
      this.$message.success('恢复成功!!', 0.5);
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 数据导入
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },

    // 撤销操作
    backwardAction(description, id) {
      const _this = this;
      this.$confirm({
        title: '提醒',
        content: `确认撤销 "${description}" 行为么,撤销后该行为将还原至前一行为。`,
        onOk() {
          _this.$api.aiCenterS.backwardBehaviorById(id).then((res) => {
            if (res.status === 200) {
              _this.$message[res.data.code === 200 ? 'success' : 'warning'](
                res.data.message
              );
              res.data.code === 200 && _this._getAnalyze();
            }
          });
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.audit-action-containter {
  .action-bar {
    justify-content: space-between;

    .search-bar {
      display: flex;

      max-width: calc(100% - 370px - 20px);

      .conditions {
        flex-wrap: wrap;
        margin-bottom: -10px;
        & > * {
          margin-bottom: 10px;
        }
      }

      .search-reset-btns {
        display: flex;
      }

      & + .btns {
        // margin-left: 10px;
      }
    }
  }

  ::v-deep .ant-table-wrapper {
    .ant-table-thead {
      tr {
        th {
          padding: 16px 2px;
          text-align: center;
        }
      }
    }

    .ant-table-body {
      .ant-table-tbody {
        tr {
          td {
            padding: 16px 2px;
            text-align: center;

            .backward-btn {
              background: url(~@assets/home/backward.png) 0 0 / contain
                no-repeat;
              cursor: pointer;
              display: block;
              height: 18px;
              margin: 0 auto;
              width: 18px;
              &.disabled {
                cursor: not-allowed;
                filter: grayscale(1);
              }
            }
          }
        }
      }
    }  
  }
} 
</style>
