<template>
  <a-modal
    title="Execl导入"
    width="30%"
    okText="导入"
    :visible="true"
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="file-import-container">
      <div class="tip">导入的模板是现有行为审计列表导出的格式。</div>
      <div class="content">
        <div class="pick-file">
          <div class="label">选择文件:</div>
          <a-upload
            name="file"
            :multiple="true"
            :action="action"
            :headers="headers"
            :before-upload="beforeUpload"
            :showUploadList="false"
            accept="application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <a-input v-model="file.name" />
            <a-button type="primary">选择文件</a-button>
          </a-upload>
        </div>
        <div class="remark">
          <p>
            只有未撤销的行为审计支持对部分字段进行更改导入，请通过查询条件将未撤销的行为过滤出来
          </p>
          <p>导入格式支持xls、xlsx</p>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'fileImport',
  props: {
    uploadUrl: String,
  },
  data() {
    return {
      action: `${window.g.baseUrl}/data/action-log/action-import`,
      headers: {
        authorization: 'authorization-text',
      },
      file: '',
    };
  },

  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleOk() {
      this.$fullLoading({
        tip: '导入中···请稍候',
        duration: 0,
      });
      const _this = this;
      const formData = new FormData();
      formData.append('file', this.file);
      this.$api.aiCenterS
        .uploadXlsFile(this.uploadUrl, formData)
        .then(res => {
          if (res.status === 200 && res.data.code === 200) {
            _this.$message.success('导入成功');
            _this.$emit('close');
          }
        })
        .finally(() => {
          _this.$closeFullLoading();
        });
    },
    // handleChange(info) {},
    beforeUpload(file) {
      if (
        ![
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(file.type)
      ) {
        this.$message.warn('导入格式仅支持xls、xlsx!');
        return;
      } else if (file.size / 1024 / 1024 > 2) {
        this.$message.warn('文件大小仅支持小于2M!');
        return;
      }
      this.file = file;
      return false;
    },
  },
};
</script>

<style scoped lang="less">
.file-import-container {
  margin: 0 auto;
  width: fit-content;

  .tip {
    margin-bottom: 15px;
  }

  ::v-deep .content {
    .pick-file {
      display: flex;
      margin-bottom: 5px;

      .label {
        width: 80px;
        & + span {
          .ant-upload {
            background-color: transparent !important;
            &[role='button'] {
              display: flex;

              input.ant-input {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
    .remark {
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      padding-left: 80px;
    }
  }
}
</style>
